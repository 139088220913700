/* ==========================================================================
   Navigations
   ========================================================================== */

.bankitalia-navigation {
    @extend %helper--empty-list;
    
    display: flex;

    @include respond-below(sm) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__item { 
        margin-left: 40px; 

        @include respond-below(lg) {
            margin-left: 28px;
        }
        @include respond-below(md) {
            margin-left: 20px;
        }
        @media (max-width: 767px) {
            margin-left: 0;
        }
        & + .bankitalia-navigation__item {
            @include respond-below(sm) {
                margin-top: 20px;
            }
        }
    }
    a {
        position: relative;

        padding-bottom: 1px;

        font-family: $font_rubik;
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 1px;
        color: $color_white;

        @include respond-below(lg) {
            font-size: 16px;
        }
        @include respond-below(md) {
            font-size: 18px;
        }
        @include respond-below(sm) {
            font-size: 20px;
            
            color: $color_black;
        }

        &.active {
            font-weight: 800;
            
            border-bottom: 2px solid $color_white;
            
            cursor: default;   
            
            @include respond-below(sm) {
                border-color: $color_black;
            }
        }
        &:hover {
            text-decoration: none;
            border-bottom: 2px solid $color_white;

            @include respond-below(sm) {
                border-color: $color_black;
            }
        }
        span {
            @include respond-below(md) {
                display: none;
            }
    
        }
    }
    &--alt {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        overflow: auto;
        height: 100vh;

        .bankitalia-navigation {
            &__item { 
                width: 100%;
                margin-left: 0;

                & + .bankitalia-navigation__item {
                    border-top: 2px solid $color_gray-3;

                    @include respond-below(sm) {
                        margin-top: 0;
                    }
                }
            }
        }
        a {
            &:hover {
                border: none;
            }
        }
    }
}