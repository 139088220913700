// coins
.s1p2s1 {
    .bankitalia-circle {
        &--1 {
            top: 250px;
            left: 342px;

            @include respond-below(sm) {
                top: 60%;
                left: 47%;
            }
            @media (max-width: 500px) {
                left: 45%;
            }
            @include respond-below(xxs) {
              
            }
        }
        &--2 {
            top: 103px;
            left: 75px;

            @include respond-below(sm) {
                left: 9%;
                top: 25%;
            }
            @include respond-below(xs) {
                left: 8.5%;
            }
            @media (max-width: 500px) {
                left: 8%;
                top: 24%;
            }
            @media (max-width: 400px) {
                left: 7.5%;
                top: 22%;
            }
            @media (max-width: 360px) {
                left: 7%;
            }
            @media (max-width: 320px) {
                top: 21%;
                left: 6%;
            }
        }
        &--3 {
            top: 29px;
            left: 452px;

            @include respond-below(sm) {
                top: 8%;
                left: 62%;
            }
            @include respond-below(xs) {
                top: 7.5%;
                left: 61.5%;
            }
            @media (max-width: 500px) {
                top: 6.5%;
                left: 60.5%;
            }
            @media (max-width: 400px) {
                top: 5.5%;
                left: 59.5%;
            }
            @media (max-width: 360px) {
                top: 4.5%;
            }
            @media (max-width: 320px) {
                top: 3.5%;
                left: 59%;
            }
        }
        &--4 {
            top: 29px;
            left: 247px;

            @include respond-below(sm) {
                top: 8%;
                left: 33%;
            }
            @include respond-below(xs) {
                top: 7.5%;
                left: 33%;
            }
            @media (max-width: 500px) {
                top: 6.5%;
                left: 32%;
            }
            @media (max-width: 400px) {
                top: 5.5%;
                left: 31%;
            }
            @media (max-width: 360px) {
                top: 4.5%;
            }
            @media (max-width: 320px) {
                top: 3.5%;
                left: 30%;
            }
        }
        &--5 {
            top: 105px;
            left: 620px;

            @include respond-below(sm) {
                top: 26%;
                left: 85%;
            }
            @include respond-below(xs) {
                top: 25.5%;
                left: 85%;
            }
            @media (max-width: 500px) {
                top: 24.5%;
                left: 84.5%;
            }
            @media (max-width: 400px) {
                top: 22.5%;
                left: 83.5%;
            }
            @media (max-width: 360px) {
                top: 21.5%;
                left: 83%;
            }
            @media (max-width: 320px) {
                top: 20.5%;
                left: 82%;
            }
        }
    }
}

// fruit
.s1p2s2 {
    .bankitalia-circle {
        &--1 {
            top: 230px;
            left: 320px;

            @include respond-below(sm) {
                top: 50%;
                left: 45%;
            }
        }
        &--2 {
            top: 311px;
            left: 150px;

            @include respond-below(sm) {
                left: 19%;
                top: 73%;
            }
        }
        &--3 {
            top: 100px;
            left: 74px;

            @include respond-below(sm) {
                top: 22%;
                left: 9%;
            }
        }
        &--4 {
            top: 15px;
            left: 342px;

            @include respond-below(sm) {
               top: 5%;
               left: 48%;
            }
        }
        &--5 {
            top: 208px;
            left: 574px;

            @include respond-below(sm) {
                top: 48%;
                left: 80%;
            }
        }
    }
}