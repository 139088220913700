/* ==========================================================================
   Teasers
   ========================================================================== */

.bankitalia-teaser {
    display: flex;
    justify-content: center;

    padding-bottom: 10px;

    &__col {
        &--pic {
            display: flex;
            justify-content: center;
            align-items: center;
            
            padding-bottom: 40px;
            padding-left: 0;
            padding-right: 5px;

            @include respond-below(sm) {
                margin-bottom: 40px;
            }
        }
        &--desc {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;                        

            @include respond-below(sm) {
                align-items: center;
                text-align: center;
                padding-bottom: 4px;
            }
        }
    }    
    .bankitalia-title { 
        margin-bottom: 20px;
        
        @include respond-below(md) {
            margin-bottom: 15px;
        }
        br {
            @include respond-below(md) {
                display: none;
            }   
        }
    }
    p { 
        margin-bottom: 40px; 

        @include respond-below(md) {
            margin-bottom: 30px;
        }
    }
}