/* ==========================================================================
   Modals
   ========================================================================== */


.modal {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @at-root .scroll-lock & {
        overflow: auto;


    @include respond-below(xs) {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    @include respond-below(xxs) {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    }
    position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
    left: 0;

    z-index: 1050;

    margin: auto;
    overflow: hidden;
	
    outline: 0;
    &.in {
        z-index: 2147483648 !important;

        .bankitalia-modal {
            opacity: 1;
        }
    }
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    z-index: 1040;

	background-color: $color_black;
    opacity: 0;

    transition: opacity ease-in-out 150ms;

    &.in {
        opacity: 0.1;
    }
}

.modal-open {
    .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

/*========================================================================== */

.bankitalia-modal {
    position: relative;    

    width: 100%;
    max-width: calc(80vw - 50px);
    padding: 90px 24px 90px;
    margin: auto;

    background-color: $color_white;
    border-radius: 10px;
    box-shadow: $box_shadow-default;
    opacity: 0;

    transition: all ease-in-out 300ms;

    @include respond-below(lg) {
        max-width: calc(95vw - 25px);
    }
    @include respond-below(xs) {
        max-width: calc(100vw - 25px);
        padding-right: 6px;
        padding-left: 6px;
        padding-top: 45px;
        padding-bottom: 45px;
    }
    @include respond-below(xxs) {
        padding-right: 0;
        padding-left: 0;
    }
    &__close {
        position: absolute;
        top: 45px;
        right: 45px;
        z-index: 1;

        @include respond-below(sm) {
            top: 35px;
            right: 35px;
        }
        @include respond-below(xs) {
            top: 20px;
            right: 20px;

            width: 26px;
            height: 26px;
        }
    }
    &__content {
        height: 100%;

        .row {
            height: 100%;
        }
    }
    &--question {
        padding-bottom: 120px;

        @include respond-below(md) {
            padding-bottom: 40px;
        }
        .bankitalia-modal {
            &__col {
                &--tips {
                    display: flex;
                    align-items: flex-end;

                    @include respond-below(md) {
                        order: 1;
                    }
                }
                &--question {
                    @include respond-below(md) {
                        order: 0;
                    }
                }
            }
        }
        .bankitalia-tips {
            transform: translateY(120px);

            @include respond-below(md) {
                transform: translateY(40px);
            }
        }
    }
    &--pin,
    &--amount,
    &--shoplist,
    &--drag,
    &--info {
        .bankitalia-modal {
            &__content {
                max-width: 660px;
                padding-right: 0;
                padding-left: 0;

                @include respond-below(xs) {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
        }
    }
    &--cards {
        .bankitalia-modal {
            &__content {
                max-width: 620px;
                padding-right: 0;
                padding-left: 0;

                @include respond-below(xs) {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
        }
    }
    &--shoplist {
        .bankitalia-quiz {
            &__question {
                line-height: 52px;

                @include respond-below(sm) {
                    line-height: 45px;
                }
                @include respond-below(xs) {
                    line-height: 40px;
                }
            }
        }
    }
}