/* ==========================================================================
   Mixins
   ========================================================================== */

//// media queries

// media queries for above breakpoints
// usage: @include respond-below(sm) {...}

@mixin respond-below($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}
@mixin respond-above($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (min-width: ($breakpoint-value)) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

// getting breakpoints values
// usage: @include respond-below(sm) {...}

@function breakpoint($breakpointName) {
    @if map-has-key($breakpoints, $breakpointName) {
        @return map-get($breakpoints, $breakpointName);
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}