/* ==========================================================================
   Header
   ========================================================================== */

.bankitalia-header {
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__col {
        &--nav {
            display: flex;
            justify-content: flex-end;

            @include respond-below(sm) {
                position: fixed;
                top: 0;
                right: 0;
                z-index: 10;
                transform: translateX(100%);

                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                width: 50vw;
                min-width: 300px;

                background-color: $color_white;

                opacity: 0;
            }
            &.transitions {
                @include respond-below(sm) {
                    transition: all ease-in-out 300ms;
                }
            }
        }
        &--chapters {
            justify-content: center;

            @include respond-below(xs) {
                order: 3;
                margin-top: 30px;
            }
        }
        &--hamburger {
            display: none;
            justify-content: flex-end;

            @include respond-below(sm) {
                display: flex;
            }
        }
    }
    .bankitalia-logo-app {
        width: 121px;
    }
    .bankitalia-navigation-close {
        position: absolute;
        top: 30px;
        right: 20px;

        display: none;

        @include respond-below(sm) {
            display: block;
        }
        @include respond-below(xs) {
            top: 15px;
            right: 15px;
            width: 26px;
            height: 26px;
        }
    }
    .bankitalia-hamburger {
        border: none;
        background: none;
    }

    #bankitalia-page-navbar {
        max-width: 550px;
    }
}

