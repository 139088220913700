/* ==========================================================================
   Other
   ========================================================================== */

.bankitalia-list {
	font-family: $font_faunaOne;
	font-size: 24px;

	@include respond-below(sm) {
		font-size: 20px;
	}
	@include respond-below(xs) {
		font-size: 18px;
	}
	&__item {
		position: relative;

		padding-left: 20px;

		&:before {
			position: absolute;
			left: 0;
			top: 12px;

			width: 6px;
			height: 6px;

			border-radius: 50%;
			background-color: $color_white;

			content: "";
		}
		& + & {
			margin-top: 15px;
		}
	}
	&--numeric {
		counter-reset: li;

		.bankitalia-list {
			&__item {
				padding-left: 0;

				&:before {
					position: static;

					margin-right: 15px;
					width: auto;
					height: auto;

					border-radius: 0;
					background-color: transparent;

					counter-increment: li;
					content: counter(li)"."
				}
			}
		}
	}
}

/*========================================================================== */

.bankitalia-featured-list {
	&__item {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		font-family: $font-rubik;
		font-size: 24px;
		font-weight: 500;

		@include respond-below(sm) {
			font-size: 20px;
		}
		@include respond-below(xs) {
			font-size: 18px;
		}
		& + & {
			margin-top: 20px;
		}
		figure {
			display: flex;
			justify-content: center;
			flex-shrink: 0;

			width: 77px;
			margin-right: 28px;

			@include respond-below(sm) {
				width: 60px;
				margin-right: 20px;
			}
			img {
				@include respond-below(sm) {
					max-width: 75%;
				}
			}
		}
	}
}

/*========================================================================== */

.bankitalia-separator {
	transform: translateX(-100px);

	height: 1px;
	width: calc(100% + 200px);

	background-color: $color_white;
}