/* ==========================================================================
   Styles
   ========================================================================== */

.bankitalia-dev-hotspot-id {
   opacity: 0 !important;

   margin-top: 60px;

   width: 200px;
   height: 25px;
   padding-top: 3px;

   font-size: 11;
   text-align: center;
   line-height: 10px;
   vertical-align: middle;
   color: #000;

   outline: 1px solid #000;
   background-color: rgba(255, 255, 255, 0.35);
}

.bankitalia-circle__info-label {
   margin-top: -125px;
   margin-left: -30px;

   display: block;
   width: 55px;
   height: 132px;

   background-image: url(/images/shared/info-tooltip-white.png);
   background-size: 55px auto;
   background-position: top left;
   background-repeat: no-repeat;
}
.bankitalia-circle__info-label span {
   display: none;
}

.bankitalia-hotspot--highlighted {
   background-color: red !important;
}

.bankitalia-info-page a {
   text-decoration: underline;
}

// Globals
@import 'base/vars';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/fonts';
@import 'base/typography';
@import 'base/global';

// Plugins
@import 'components/plugins/swiper';

// Layout
@import 'main/header';
@import 'main/content';
@import 'main/footer';

// Sections & components
@import 'components/forms/global';
@import 'components/buttons';

@import 'components/navigations/main';
@import 'components/navigations/pagination';

@import 'components/modals';
@import 'components/carousel';
@import 'components/teasers';
@import 'components/chapters';
@import 'components/game';
@import 'components/message';
@import 'components/article';
@import 'components/circlesPosition';

@import 'components/other';

// States
@import 'main/states';




