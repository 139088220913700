.bankitalia-kids {
  position: relative;
  width: 100vw;
  max-width: 750px;
  /* height: 445px; */
  padding-top: 60%;
}

.bankitalia-kids__frame {
  position: absolute;
  left: 0;
  top: 0;
}

.bankitalia-kids.bankitalia-kids__layer-wrapper {
  position: relative;
  z-index: 1;
}
.bankitalia-kids .bankitalia-kids__main-layer,
.bankitalia-kids .bankitalia-kids__body-shadow,
.bankitalia-kids .bankitalia-kids__floor-shadow {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0;

  width: 100% !important;
  height: 113% !important;

  padding-bottom: 10%;
}
.bankitalia-kids .bankitalia-kids__main-layer img,
.bankitalia-kids .bankitalia-kids__body-shadow img,
.bankitalia-kids .bankitalia-kids__floor-shadow img {
  margin-left: 3% !important;

  width: 94% !important;
  /* height: 90% !important; */
}

@media (max-width: 767px) {
  .bankitalia-kids .bankitalia-kids__main-layer img,
  .bankitalia-kids .bankitalia-kids__body-shadow img,
  .bankitalia-kids .bankitalia-kids__floor-shadow img {
    width: 98% !important;
    margin-left: 1% !important;
  }
}

.bankitalia-kids .bankitalia-kids__main-layer {
  z-index: 3 !important;
}
.bankitalia-kids .bankitalia-kids__body-shadow {
  z-index: 2 !important;
}
.bankitalia-kids .bankitalia-kids__floor-shadow {
  z-index: 1 !important;
}

.bankitalia-kids .bankitalia-kids__body-shadow {
  top: -4px !important;
  left: -5px !important;

  filter: grayscale(100%) blur(3px) contrast(10%);

  opacity: 0.15;
}

.bankitalia-kids .bankitalia-kids__floor-shadow {
  bottom: 10% !important;
  left: -40px !important;

  transform: rotateX(180deg);
  filter: grayscale(100%) blur(15px) contrast(0%);

  opacity: 0.4;
}
.bankitalia-kids .bankitalia-kids__floor-shadow img {
  width: 100% !important;
  height: 20px !important;

  margin-top: 7.5% !important; /* REMEMBER, this is reversed, so top is bottom and bottom is top */
}

/* fade in */
.bankitalia-kids__animated.fadeInDown {
  opacity: 1;
  animation: fadeInDown 1s 0.0s 1 cubic-bezier(0.28, 0.84, 0.42, 1);
}

/* left -> right */
.bankitalia-kids__animated.fromLeft .bankitalia-kids__frame--fastest {
  transform: skewX(-50deg);
  animation: fromLeft--fastest 2s 0.0s 1 ease-in-out both, jump--right 14s 1.9s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bankitalia-kids__animated.fromLeft .bankitalia-kids__frame--fast {
  transform: translateX(100vw) skewX(-25deg);
  animation: fromLeft--fast 2s 0.2s 1 ease-in-out both, jump--right 14s 2s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bankitalia-kids__animated.fromLeft .bankitalia-kids__frame--slow {
  transform: translateX(100vw) skewX(-15deg);
  animation: fromLeft--slow 2s 0.45s 1 ease-in-out both, jump--left 14s 2.05s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bankitalia-kids__animated.fromLeft .bankitalia-kids__frame--slowest {
  transform: translateX(100vw) skewX(-10deg);
  animation: fromLeft--slowest 2s 0.55s 1 ease-in-out both, jump--left 14s 2s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

/* right -> left */
.bankitalia-kids__animated.fromRight .bankitalia-kids__frame--fastest {
  transform: translateX(100vw) skewX(-30deg);
  animation: fromRight 2s 0.3s 1 ease-out both, jump--right 16s 8s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bankitalia-kids__animated.fromRight .bankitalia-kids__frame--fast {
  transform: translateX(100vw) skewX(-30deg);
  animation: fromRight 2s 1.5s 1 ease-out both, jump--left 16s 14s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bankitalia-kids__animated.fromRight .bankitalia-kids__frame--slow {
  transform: translateX(100vw) skewX(-30deg);
  animation: fromRight 2s 1.15s 1 ease-out both, jump--right 16s 20s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bankitalia-kids__animated.fromRight .bankitalia-kids__frame--slowest {
  transform: translateX(100vw) skewX(-30deg);
  animation: fromRight 2s 1.5s 1 ease-out both, jump--left 16s 17s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

/* from TOP */
/* .bankitalia-kids__animated.fromTop .bankitalia-kids__frame--fastest {
  transform: translateX(-100vw) rotate(-45deg) skew(-20deg);
  animation: fromTop 2s 1s 1 ease-out both, jump--right 14s 2s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
} */

.bankitalia-kids__animated.fromTop .bankitalia-kids__frame--fast {
  transform: translateY(-100vh) skewX(-30deg);
  animation: fromTop 2s 0s 1 ease-in-out both, jump--right 14s 2s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

/* .bankitalia-kids__animated.fromTop .bankitalia-kids__frame--slow {
  transform: translateX(-100vw) rotate(-45deg) skew(-12.5deg);
  animation: fromTop 2s 1.75s 1 ease-out both, jump--right 14s 6s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bankitalia-kids__animated.fromTop .bankitalia-kids__frame--slowest {
  transform: translateX(-100vw) rotate(-45deg) skew(-10deg);
  animation: fromTop 2s 2s 1 ease-out both, jump--left 14s 9s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
} */




@keyframes fadeInDown {
  0% {
      -webkit-transform: translateY(-5vh);
      transform: translateY(-5vh);
      opacity: 0;
  }
  60% {
      -webkit-transform: translateY(-2vh);
      transform: translateY(-2vh);
      opacity: 0.6;
  }
  80% {
      -webkit-transform: translateY(-1vh);
      transform: translateY(-1vh);
      opacity: 0.8;
  }
  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
  }
}


@keyframes fromLeft--fastest {
  0% {
      transform: translateX(-100vw) rotate(-45deg) skew(-10deg);
      opacity: 0;
  }
  60% {
      transform: translateX(10%) rotate(0);
      opacity: 1;
  }
  80% {
      transform: translateX(0) rotate(0);
      opacity: 1;
  }
  100% {
      transform: translateX(0) rotate(0);
      opacity: 1;
  }
}
@keyframes fromLeft--fast {
  0% {
    transform: translateX(-100vw) rotate(-15deg);
    opacity: 0;
  }
  60% {
      transform: translateX(5%) rotate(5deg);
      opacity: 1;
  }
  80% {
      transform: translateX(0) rotate(0);
      opacity: 1;
  }
  100% {
      transform: translateX(0) rotate(0);
      opacity: 1;
  }
}
@keyframes fromLeft--slow {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }
  60% {
      transform: translateX(2.5%);
      opacity: 1;
  }
  80% {
      transform: translateX(0);
      opacity: 1;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes fromLeft--slowest {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }
  60% {
      transform: translateX(0.5%);
      opacity: 1;
  }
  80% {
      transform: translateX(0);
      opacity: 1;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes fromRight {
  0% {
      -webkit-transform: translateX(100vw) rotate(25deg);
      transform: translateX(100vw) rotate(25deg);
      opacity: 0;
  }
  60% {
      -webkit-transform: translateX(-10%) rotate(-10deg);
      transform: translateX(-10%) rotate(-10deg);
      opacity: 1;
  }
  80% {
      -webkit-transform: translateX(0) rotate(0);
      transform: translateX(0) rotate(0);
      opacity: 1;
  }
  100% {
      -webkit-transform: translateX(0) rotate(0);
      transform: translateX(0) rotate(0);
      opacity: 1;
  }
}

@keyframes fromTop {
  0% {
      -webkit-transform: translateY(-100vh) rotate(25deg);
      transform: translateY(-100vh) rotate(25deg);
      opacity: 0;
  }
  /* 60% {
      -webkit-transform: translateY(-40vh) rotate(-10deg);
      transform: translateY(-40vh) rotate(-10deg);
      opacity: 1;
  }
  80% {
      -webkit-transform: translateY(-20vh) rotate(0);
      transform: translateY(-20vh) rotate(0);
      opacity: 1;
  } */
  100% {
      -webkit-transform: translateY(0) rotate(0);
      transform: translateY(0) rotate(0);
      opacity: 1;
  }
}

@keyframes jump--left {
  0% {
      transform: scale(1, 1) translateY(0);
  }
  2% {
      transform: scale(1, 0.98) translateY(0);
  }
  4% {
      transform: scale(1, 1.02) translateY(-20px) rotate(2deg);
  }
  6% {
      transform: scale(1, 0.99) translateY(0);
  }
  7% {
      transform: scale(1, 1.01) translateY(-5px);
  }
  9% {
      transform: scale(1, 1) translateY(0);
  }
  100% {
      transform: scale(1, 1) translateY(0);
  }
}

@keyframes jump--right {
  0% {
      transform: scale(1, 1) translateY(0);
  }
  2% {
      transform: scale(1, 0.98) translateY(0);
  }
  4% {
      transform: scale(1, 1.02) translateY(-28px) rotate(-2deg);
  }
  6% {
      transform: scale(1, 0.99) translateY(0);
  }
  7% {
      transform: scale(1, 1.01) translateY(-5px);
  }
  9% {
      transform: scale(1, 1) translateY(0);
  }
  100% {
      transform: scale(1, 1) translateY(0);
  }
}
