/* ==========================================================================
   Carousel
   ========================================================================== */

.bankitalia-hero {
    position: relative;

    justify-content: center;
    padding-left: 110px;
    padding-right: 110px;

    @include respond-below(md) {
        padding-left: 90px;
        padding-right: 90px;
        padding-bottom: 80px;
    }
    @include respond-below(xs) {
        padding-left: 0;
        padding-right: 0;
    }
    &__nav {
        position: absolute;
        top: 50%;
        margin-top: -33px;

        width: 67px;
        height: 67px;

        border: 2px solid $color_white;
        border-radius: 100px;
        background-color: transparent;

        transition: all ease-in-out 300ms;

        @include respond-below(lg) {
            margin-top: -30px;

            width: 60px;
            height: 60px;
        }
        @include respond-below(md) {
            margin-top: -24px;

            width: 47px;
            height: 47px;
        }
        @include respond-below(sm) {
            margin-top: -21px;

            width: 42px;
            height: 42px;
        }
        @include respond-below(xs) {
            display: none;
        }

        &:after {
            @extend %helper--bg-contained;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            width: 14px;
            height: 21px;

            background-image: url(/images/icons/arrow.png);

            @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
                background-image: url(/images/icons/arrow2x.png);
            }

            content: "";
            transition: all ease-in-out 300ms;

            @include respond-below(md) {
                width: 10px;
                height: 15px;
            }
        }
        &--prev {
            left: 0;
            z-index: 9999;

            &:after {
                transform: translate(-55%,-50%) rotate(180deg);
            }
            &:hover {
                &:after {
                    transform: translate(-75%, -50%) rotate(180deg);
                }
            }
        }
        &--next {
            right: 0;
            z-index: 9999;

            &:hover {
                &:after {
                    transform: translate(-30%, -50%);
                }
            }
        }
        &:hover {
            opacity: 1;

            @at-root .bankitalia-palette-pink & {
                background-color: $color_pink-2;
            }
            @at-root .bankitalia-palette-blue & {
                background-color: darken($color_blue-2, 7%);
            }
            @at-root .bankitalia-palette-orange & {
                background-color: darken($color_orange, 7%);
            }
            @at-root .bankitalia-palette-green & {
                background-color: darken($color_green-2, 7%);
            }
            @at-root .bankitalia-palette-aqua & {
                background-color: darken($color_aqua, 7%);
            }
        }
    }
    &__pagination {
        @extend .bankitalia-pagination;

        @include respond-below(md) {
            position: absolute;
            bottom: 0;
        }
        @include respond-below(sm) {
            bottom: 20px;
        }
    }
    & > .bankitalia-hero__pagination {
        display: none;

        @include respond-below(md) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            display: flex;
        }
    }
}

/*========================================================================== */

.bankitalia-game-carousel {
    .swiper-container {
        overflow: visible;
    }    

    @include respond-below(md) {
        padding-bottom: 80px;
    }
    &__pagination {
        @extend .bankitalia-pagination;

        @include respond-below(md) {
            position: absolute;
            bottom: 0;
        }
    }
    & > .bankitalia-game-carousel__pagination {
        display: none;

        @include respond-below(md) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            display: flex;
        }
    }
    .swiper-wrapper {
        align-items: stretch;
    }
    .swiper-slide {
        display: flex;
        align-items: stretch;
        justify-content: center;
        height: auto;
        opacity: 0;
        transition: opacity ease-in-out 200ms;
    }
    .swiper-slide-active {
        opacity: 1;
    }
}