/* ==========================================================================
   Message
   ========================================================================== */

.bankitalia-message {
    position: relative;

    width: 100%;
    padding: 35px;

    font-size: 24px;
    font-weight: bold;
    text-align: center;

    background-color:$color_blue-1;
    border-radius: 10px;
    box-shadow: $box_shadow-default;

    @include respond-below(md) {
        font-size: 22px;
    }
    @include respond-below(sm) {
        padding: 30px;
    }
    @include respond-below(xs) {
        padding: 25px 15px;

        font-size: 20px;
    }
    @include respond-below(xxs) {
        padding: 20px;

        font-size: 18px;
    }
    &:before,
    &:after {
        position: absolute;

        background-color:$color_blue-1;
        content: "";
    }
    &:before {
        top: -15px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);

        width: 30px;
        height: 30px;
        
        box-shadow: $box_shadow-default;

        content: "";
    }
    &:after {
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 60px;
        height: 30px;
    }

    p {
        position: relative;
        z-index: 1;

        font-size: inherit;
    }
    .bankitalia-btn {
        margin-top: 15px;
    }
    &--success {
        background-color: $color_green-1;

        &:before,
        &:after  {
            background-color: $color_green-1;
        }
    }
    &--error {
        background-color: $color_violet-1;

        &:before,
        &:after  {
            background-color: $color_violet-1;
        }
    }
    code {
        white-space: normal;
        font-family: $font_faunaOne;
    }
}