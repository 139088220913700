/* ==========================================================================
   Game
   ========================================================================== */

.bankitalia-game-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 75px;
    padding-bottom: 75px;

    @include respond-below(sm) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &__row {
        &--header {
            margin-bottom: 40px;

            @include respond-below(sm) {
                margin-bottom: 30px;
            }
        }
        &--content {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex: 1 1 auto;
        }
    }
    &__inner {
        position: relative;

        display: flex;
        justify-content: center;
    }
    .bankitalia-title {
        display: block;
        width: 100%;

        text-align: center;

        br {
            display: none;

            @include respond-below(xs) {
                display: block;
            }
        }
    }
    .bankitalia-circle {
        position: absolute;

        &.active {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .bankitalia-game-slide {
        &__inner {
            max-width: 100%;
            // max-width: 660px;

            // @include respond-below(md) {
            //     max-width: 620px;
            // }
            // @include respond-below(sm) {
            //     max-width: 440px;
            // }
            // @include respond-below(xs) {
            //     max-width: 300px;
            // }
            // @include respond-below(xxs) {
            //     max-width: 260px;
            // }
            // @include respond-below(xxxs) {
            //     max-width: 250px;
            // }
        }
    }
    .bankitalia-circle {
        &.active {
            top: -70px;

            @include respond-below(xs) {
                top: -100px;
            }
            &.big {
                top: -130px;
            }
        }

        &--1 {
            top: 40%;
            left: 15%
        }
        &--2 {
            top: 15%;
            left: 40%;
        }
        &--3 {
            top: 27%;
            left: 60%;
        }
        &--4 {
            top: 65%;
            left: 75%;
        }
        &--5 {
            top: 70%;
            left: 47.5%;
        }
    }
}

/*========================================================================== */

.bankitalia-game-result {
    @include respond-below(md) {
        padding-bottom: 150px;
    }
    @include respond-below(sm) {
        padding-bottom: 220px;
    }
    @include respond-below(xs) {
        padding-bottom: 0;
    }
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        @include respond-below(xs) {
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
        }
    }
    &__main {
        position: relative;

        padding-top: 70px;

        @include respond-below(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding-top: 300px;
        }
        @include respond-below(sm) {
            padding-top: 250px;
        }
        @include respond-below(xs) {
            margin-bottom: 50px;
        }
    }
    &__kid {
        @include respond-below(sm) {
            max-width: 300px;
        }
        @include respond-below(xxs) {
            max-width: 260px;
        }
    }
    &__box {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 9999;

        width: 350px;
        padding: 35px;

        text-align: center;

        background-color: $color_white;
        border-radius: 10px;
        box-shadow: $box_shadow-default;

        @include respond-below(lg) {
            max-width: 300px;
            left: auto;
            right: 0;
        }
        @include respond-below(md) {
            right: auto;
            transform: none;

            margin-bottom: 50px;
            padding: 25px 30px;
        }
        @include respond-below(xxs) {
            max-width: 280px;
        }
        &:before {
            @extend %helper--pseudo-triangle;

            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);

            border-width: 20px 20px 20px 0;
            border-color: transparent $color_white transparent transparent;

            @include respond-below(md) {
                top: auto;
                bottom: -20px;
                left: 50%;
                transform: translateX(-50%);

                border-width: 20px 20px 0 20px;
                border-color: $color_white transparent transparent transparent;
            }
        }
        strong,
        p {
            font-family: $font_firaSans;
        }
        strong {
            display: block;
            margin-bottom: 15px;

            font-size: 42px;
            color: $color_blue-1;

            @include respond-below(sm) {
                font-size: 36px;
            }
        }
        p {
            font-size: 28px;

            @at-root .bankitalia-palette-pink & {
                color: $color_pink-3;
            }
            @at-root .bankitalia-palette-blue & {
                color: $color_blue-2;
            }
            @at-root .bankitalia-palette-orange & {
                color: $color_orange;
            }
            @at-root .bankitalia-palette-green & {
                color: $color_green-2;
            }
            @at-root .bankitalia-palette-aqua & {
                color: $color_aqua;
            }
            @include respond-below(sm) {
                font-size: 22px;
            }
        }
    }
    .bankitalia-ico-btn {
        & + .bankitalia-ico-btn {
            margin-top: 40px;

            @include respond-below(xs) {
                margin-top: 0;
                margin-left: 60px;
            }
        }
    }
    .bankitalia-kids {
        &__layer-wrapper {
            @include respond-below(md) {
                margin-top: -130px;
            }
            @include respond-below(sm) {
                margin-top: -100px;
            }
            @include respond-below(xs) {
                margin-top: -70px;
                margin-bottom: 70px;
            }
            @include respond-below(xxs) {
                margin-top: -70px;
                margin-bottom: 40px;
            }
        }

    }
}

/*========================================================================== */

.bankitalia-quiz {
    &__question {
        @extend .bankitalia-title;
        @extend .bankitalia-title--alt;

        display: block;
        margin-top: 0;
        margin-bottom: 20px;

        color: $color_blue-1;

        @include respond-below(sm) {
            margin-bottom: 15px;
        }
        @include respond-below(xs) {
            margin-bottom: 10px;
            padding-right: 40px;
        }
        &--alt {
            @extend .bankitalia-title--alt-2;
        }
        b {
            font-weight: 900;
        }
    }
    &__desc {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 10px;
        margin-bottom: 30px;

        font-size: 18px;
        color: $color_gray-2;

        p {
            margin-top: 0;
        }
    }
    &__answers {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &--switch-1,
        &--switch-2,
        &--switch-3 {
            @include respond-below(sm) {
                padding-right: 50px;
            }
            @include respond-below(xs) {
                padding-right: 45px;
            }

            &:before,
            &:after {
                position: absolute;

                background-repeat: no-repeat;

                content: "";
            }
            &:before {
                right: -40px;

                width: 34px;
                height: calc(50% - 30px);

                background-size: 34px 100%;
                background-image: url(/images/pics/switch-short.png);

                @include respond-below(sm) {
                    right: 0;
                }
            }
            &:after {
                top: 25px;
                right: -60px;

                width: 47px;
                height: calc(100% - 50px);

                background-size: 47px 100%;
                background-image: url(/images/pics/switch-long.png);

                @include respond-below(md) {
                    right: -5px;
                }
            }
        }
        &--switch-1 {
            &:before {
                top: 35px;
            }
        }
        &--switch-2 {
            &:before {
                bottom: 35px;

                @include respond-below(md) {
                    bottom: 30px;
                }
            }
        }
        &--switch-3 {
            &:before {
                top: 35px;
            }
            &:after {
                top: auto;
                bottom: 35px;
                right: -40px;

                width: 34px;
                height: calc(50% - 30px);

                background-size: 34px 100%;
                background-image: url(/images/pics/switch-short.png);

                @include respond-below(md) {
                    bottom: 30px;
                }
                @include respond-below(sm) {
                    right: 0;
                }
            }
        }
        & > div {
            width: 100%;
        }
        .bankitalia-answer__label {
            flex-grow: 1;
        }
    }
    &__answer {
        & + .bankitalia-quiz__answer {
            margin-top: 12px;
        }
        .row {
            margin-left: 0;
            margin-right: 0;

            & > * {
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    &__answer--sort-item {
        cursor: grab;
    }
    &__answer--sort-item .bankitalia-answer__label    {
        background-color: #fff;
    }
    &__answer--sort-item-ghost .bankitalia-answer__nr {
        opacity: 0;
    }
    &__answer--sort-item-ghost .bankitalia-answer__label {
        color: #e9f3f3;
        background-color: #e9f3f3;
        border: 1px solid #d5ecec;
    }
    &__actions {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 35px;

        @include respond-below(sm) {
            margin-top: 30px;
        }
        @include respond-below(xs) {
            justify-content: center;
        }
        &--pin,
        &--amount {
            justify-content: flex-start;
            align-items: center;

            @include respond-below(sm) {
                flex-direction: column;
            }
            .bankitalia-pin,
            .bankitalia-amount {
                @include respond-below(sm) {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }
        }
        &--pin {
            flex-direction: column;
        }
        &--cards {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include respond-below(sm) {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
            .bankitalia-card {
                & + .bankitalia-card {
                    @include respond-below(sm) {
                        margin-top: 30px;
                    }
                }
            }
        }
        &--shoplist {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-wrap: wrap;

            @include respond-below(sm) {
                max-width: 450px;
                margin-left: auto;
                margin-right: auto;
            }
            .bankitalia-product {
                flex-shrink: 0;
                margin: 10px;
                cursor: pointer;

                @include respond-below(sm) {
                    margin: 5px;
                }
            }
        }
    }
    &__message {
        margin-top: 45px;

        @include respond-below(sm) {
            margin-top: 40px;
        }
    }
    &__fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &__field {
        margin-right: 2em;
    }
}

/*========================================================================== */

.bankitalia-answer {
    &__input {
        display: none;

        &:checked {
            & + .bankitalia-answer__label {
                @at-root .bankitalia-palette-pink & {
                    border-color: $color_pink-4;
                }
                @at-root .bankitalia-palette-blue & {
                    border-color: darken($color_blue-2, 7%);
                }
                @at-root .bankitalia-palette-orange & {
                    border-color: darken($color_orange, 7%);
                }
                @at-root .bankitalia-palette-green & {
                    border-color: darken($color_green-2, 7%);
                }
                @at-root .bankitalia-palette-aqua & {
                    border-color: darken($color_aqua, 7%);
                }
                &:after {
                    display: block;
                }
            }
        }
    }
    &__label {
        position: relative;

        display: flex;
        align-items: center;
        min-height: 76px;
        padding: 15px 30px 15px 90px;

        font-family: $font_faunaOne;
        font-size: 18px;
        color: $color_gray-2;

        border: 2px solid $color_gray-3;
        border-radius: 100px;

        @include respond-below(md) {
            border-radius: 40px;
        }
        @include respond-below(sm) {
            padding: 12px 25px 12px 80px;
            font-size: 16px;
            border-radius: 30px;
        }
        @include respond-below(xs) {
            padding: 10px 20px 10px 70px;
            font-size: 14px;
            border-radius: 25px;
        }
        &:before,
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            border-radius: 100px;

            content: "";
        }
        &:before {
            left: 25px;

            width: 35px;
            height: 35px;

            background-color: $color_gray-3;

            content: "";

            @include respond-below(xs) {
                left: 20px;
            }
        }
        &:after {
            display: none;
            left: 35px;

            width: 17px;
            height: 17px;

            @at-root .bankitalia-palette-pink & {
                background-color: $color_pink-4;
            }
            @at-root .bankitalia-palette-blue & {
                background-color: darken($color_blue-2, 7%);
            }
            @at-root .bankitalia-palette-orange & {
                background-color: darken($color_orange, 7%);
            }
            @at-root .bankitalia-palette-green & {
                background-color: darken($color_green-2, 7%);
            }
            @at-root .bankitalia-palette-aqua & {
                background-color: darken($color_aqua, 7%);
            }
            @include respond-below(xs) {
                left: 30px;
            }
        }
    }
    &--alt {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .bankitalia-answer {
            &__nr {
                flex-shrink: 0;
                width: 35px;

                font-family: $font_firaSans;
                font-size: 32px;
                color: $color_blue-1;

                @include respond-below(md) {
                    width: 25px;

                    font-size: 24px;
                }
                @include respond-below(xs) {
                    width: 20px;

                    font-size: 22px;
                }
            }
            &__label {
                padding-left: 40px;
                padding-right: 40px;

                @include respond-below(md) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
                @include respond-below(sm) {
                    padding-left: 25px;
                    padding-right: 25px;
                }
                @include respond-below(xs) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @include respond-below(xxs) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }
}

/*========================================================================== */

.bankitalia-tips {
    @include respond-below(md) {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
    }
    &__box {
        position: relative;
        width: 100%;
        min-height: 230px;
        max-width: 240px;
        padding: 35px;
        margin-bottom: 40px;

        font-weight: bold;
        color: $color_gray-2;

        border-radius: 10px;
        box-shadow: $box_shadow-default;

        @include respond-below(lg) {
            padding: 25px;
        }
        @include respond-below(md) {
            min-height: 160px;
            order: 1;
            margin: 20px 20px 20px 40px;
        }
        @include respond-below(sm) {
            min-height: 140px;
        }
        @include respond-below(xs) {
            padding: 20px;
        }
        &:before,
        &:after {
            position: absolute;

            background-color: $color_white;
            content: "";
        }
        &:before {
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);

            width: 30px;
            height: 30px;

            box-shadow: $box_shadow-default;

            content: "";

            @include respond-below(md) {
                top: 50%;
                bottom: auto;
                left: -15px;
                transform: translateY(-50%) rotate(45deg);
            }
        }
        &:after {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            width: 60px;
            height: 30px;

            @include respond-below(md) {
                bottom: auto;
                top: 50%;
                left: 0;
                transform: translateY(-50%);

                height: 60px;
                width: 30px;
            }
        }
        p {
            position: relative;
            z-index: 1;
        }
    }
    img {
        @include respond-below(sm) {
            height: 190px;
        }
        @include respond-below(xs) {
            height: 130px;
        }
        @include respond-below(xxs) {
            height: 115px;
        }
        @include respond-below(xxxs) {
            height: 100px;
        }
    }
}

/*========================================================================== */

.bankitalia-pin {
    display: flex;
    height: 65px;

    border: 2px solid $color_gray-3;

    @include respond-below(xs) {
        height: 60px;
    }
    @include respond-below(xxs) {
        height: 50px;
    }
    &__input,
    &__digit {
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: $font-firaSans;
        font-size: 47px;
        font-weight: bold;
        text-align: center;

        @include respond-below(xs) {
            font-size: 42px;
        }
    }
    &__input {
        border: none;
        color: $color_pink-3;

        @at-root .bankitalia-palette-pink & {
            &::-webkit-input-placeholder {
                color: $color_pink-3;
            }
            &::-moz-placeholder {
                color: $color_pink-3;
            }
            &:-ms-input-placeholder {
                color: $color_pink-3;
            }
            &:-moz-placeholder {
                color: $color_pink-3;
            }
        }
        @at-root .bankitalia-palette-blue & {
            &::-webkit-input-placeholder {
                color: $color_blue-2;
            }
            &::-moz-placeholder {
                color: $color_blue-2;
            }
            &:-ms-input-placeholder {
                color: $color_blue-2;
            }
            &:-moz-placeholder {
                color: $color_blue-2;
            }
        }
        @at-root .bankitalia-palette-orange & {
            &::-webkit-input-placeholder {
                color: $color_orange;
            }
            &::-moz-placeholder {
                color: $color_orange;
            }
            &:-ms-input-placeholder {
                color: $color_orange;
            }
            &:-moz-placeholder {
                color: $color_orange;
            }
        }
        @at-root .bankitalia-palette-green & {
            &::-webkit-input-placeholder {
                color: $color_green-2;
            }
            &::-moz-placeholder {
                color: $color_green-2;
            }
            &:-ms-input-placeholder {
                color: $color_green-2;
            }
            &:-moz-placeholder {
                color: $color_green-2;
            }
        }
        @at-root .bankitalia-palette-aqua & {
            &::-webkit-input-placeholder {
                color: $color_aqua;
            }
            &::-moz-placeholder {
                color: $color_aqua;
            }
            &:-ms-input-placeholder {
                color: $color_aqua;
            }
            &:-moz-placeholder {
                color: $color_aqua;
            }
        }
    }
    &__digit {
        color: $color_gray-2;
    }
    & > * {
        width: 65px !important;
        flex-shrink: 0;

        @include respond-below(xs) {
            width: 60px !important;
        }
        @include respond-below(xxs) {
            width: 50px !important;
        }
        & + * {
            border-left: 2px solid $color_gray-3;
        }
    }
}

/*========================================================================== */

.bankitalia-amount {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    width: 295px;
    padding: 0 28px;

    border: 2px solid $color_gray-3;
    border-radius: 100px;

    @include respond-below(xs) {
        height: 60px;
        padding: 0 20px;
    }
    &__currency {
        flex-shrink: 0;

        width: 45px;
        margin-right: 10px;

        text-align: left;
        font-family: $font-firaSans;
        font-size: 42px;
        font-weight: bold;
        color: $color_blue-1;
    }
    &__input {
        flex-grow: 1;

        width: 110px !important;
        margin-right: 30px;

        font-family: $font-firaSans;
        font-size: 42px;
        font-weight: bold;
        color: $color_gray-2;

        border: none;
    }
    &__controls {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    &__increase,
    &__decrease {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 30px;
    }
    &--static {
        display: inline-flex;
        height: 45px;
        padding: 1px 28px 0 18px;

        @include respond-below(xs) {
            height: 40px;
            padding-left: 14px;
            padding-right: 20px;
        }
        .bankitalia-amount {
            &__currency {
                margin-right: 20px;

                @include respond-below(xs) {
                    font-size: 28px;
                    margin-right: 15px;
                }
            }
            &__amount {
                color: $color_gray-2;
            }
        }
    }
}

/*========================================================================== */

.bankitalia-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__frame {
        width: 260px;
        height: 155px;
        margin-bottom: 14px;

        border: 2px dashed $color_gray-3;
        border-radius: 15px;
    }
    &__name {
        font-size: 18px;
        text-align: center;

        @at-root .bankitalia-palette-pink & {
            color: $color_pink-3;
        }
        @at-root .bankitalia-palette-blue & {
            color: $color_blue-2;
        }
        @at-root .bankitalia-palette-orange & {
            color: $color_orange;
        }
        @at-root .bankitalia-palette-green & {
            color: $color_green-2;
        }
        @at-root .bankitalia-palette-aqua & {
            color: $color_aqua;
        }
    }
}

/*========================================================================== */

.bankitalia-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 190px;
    height: 190px;

    border-radius: 200px;
    transition: box-shadow ease-in-out 200ms;

    @include respond-below(sm) {
        width: 140px;
        height: 140px;
    }
    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 160px;

        @include respond-below(sm) {
            height: 100px;
        }
        img {
            max-height: 112px;

            @include respond-below(sm) {
                max-height: 75px;
            }
        }
    }
    &__price {
        padding-bottom: 10px;

        font-family: $font-firaSans;
        font-size: 28px;
        color: $color_blue-1;

        @include respond-below(sm) {
            font-size: 24px;
        }
    }
    &.active,
    &:hover {
        box-shadow: $box_shadow-default;
    }
}

/*========================================================================== */

.bankitalia-drag {
    .highlighted .bankitalia-drag__answer__field{
        background-color: #e9f3f3;
        border: 1px solid #d5ecec;
    }

    &__answers-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 45px;

        @include respond-below(xs) {
            margin-bottom: 30px;
        }
        @include respond-below(xxs) {
            margin-bottom: 45px;
            align-items: center;
        }
    }
    &__answer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include respond-below(xxs) {
            flex-direction: column;
        }
        & + .bankitalia-drag__answer {
            margin-top: 30px;

            @include respond-below(xs) {
                margin-top: 20px;
            }
            @include respond-below(xxs) {
                margin-top: 30px;
            }
        }

        &__field {
            flex-shrink: 0;
            width: 195px;
            height: 64px;
            margin-right: 20px;

            font-family: $font_faunaOne;

            border: 2px dashed $color_gray-3;

            @include respond-below(sm) {
                width: 180px;
            }
            @include respond-below(xs) {
                width: 150px;
                height: 55px;
                margin-right: 12px;
            }
            @include respond-below(xxs) {
                order: 1;
                margin-top: 10px;
                margin-right: 0;
            }
            &.active {
                border: none;
            }
            .bankitalia-block {
                &:hover {
                    @at-root .bankitalia-palette-pink & {
                        color: $color_pink-3;
                    }
                    @at-root .bankitalia-palette-blue & {
                        color: $color_blue-2;
                    }
                    @at-root .bankitalia-palette-orange & {
                        color: $color_orange;
                    }
                    @at-root .bankitalia-palette-green & {
                        color: $color_green-2;
                    }
                    @at-root .bankitalia-palette-aqua & {
                        color: $color_aqua;
                    }
                    background-color: $color_white;
                    cursor: default;
                }
            }
        }
        &__txt {
            font-size: 18px;
            color: $color_gray-2;

            @include respond-below(sm) {
                font-size: 16px;
            }
            @include respond-below(xs) {
                font-size: 14px;
            }
            @include respond-below(xxs) {
                text-align: center;
            }
        }
    }
    &__blocks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: -3px;
        width: calc(100% + 6px);

        .bankitalia-block {
            width: 215px;
            margin: 3px;

            @include respond-below(sm) {
                width: 190px;
            }
            @include respond-below(xs) {
                width: 150px;
            }
        }

        .bankitalia-block--ghost{
            opacity: 0.25;
            border: 2px dashed #ccc;
            background-color: #efefef !important;
            box-shadow: none !important;
        }
    }
}

/*========================================================================== */

.bankitalia-info {
    &__answer {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 40px;
        padding: 36px;
        border: 2px solid $color_gray-3;

        @include respond-below(xs) {
            flex-direction: column;
            padding: 30px;

            text-align: center;
        }
        @include respond-below(xs) {
            padding: 24px;
        }
        &:before,
        &:after {
            @extend %helper--pseudo-triangle;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        &:before {
            bottom: -21px;

            border-width: 21px 20px 0 20px;
            border-color: $color_gray-3 transparent transparent transparent;
        }
        &:after {
            bottom: -18px;

            border-width: 18px 18px 0 18px;
            border-color: $color_white transparent transparent transparent;
        }
    }
    &__name {
        @extend .bankitalia-title;
        @extend .bankitalia-title--alt;

        margin-right: 30px;

        @at-root .bankitalia-palette-pink & {
            color: $color_pink-3;
        }
        @at-root .bankitalia-palette-blue & {
            color: $color_blue-2;
        }
        @at-root .bankitalia-palette-orange & {
            color: $color_orange;
        }
        @at-root .bankitalia-palette-green & {
            color: $color_green-2;
        }
        @at-root .bankitalia-palette-aqua & {
            color: $color_aqua;
        }
        @include respond-below(xs) {
            width: 100%;
            margin-bottom: 10px;

            text-align: center;
        }
        &:after {
            content: ":";
        }
    }
    &__desc {
        font-size: 18px;

        color: $color_gray-2;

        @include respond-below(sm) {
            font-size: 16px;
        }
        @include respond-below(xs) {
            font-size: 14px;
        }
    }
    &__blocks {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: -3px;
        width: calc(100% + 6px);

        @include respond-below(xs) {
            justify-content: center;
        }
        .bankitalia-block {
            margin: 4px;
        }
    }
}

/*========================================================================== */

.bankitalia-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 10px;

    font-family: $font_faunaOne;
    font-size: 18px;
    text-align: center;

    border-radius: 5px;
    box-shadow: $box_shadow-default;
    transition: all ease-in-out 200ms;

    @at-root .bankitalia-palette-pink & {
        color: $color_pink-3;
    }
    @at-root .bankitalia-palette-blue & {
        color: $color_blue-2;
    }
    @at-root .bankitalia-palette-orange & {
        color: $color_orange;
    }
    @at-root .bankitalia-palette-green & {
        color: $color_green-2;
    }
    @at-root .bankitalia-palette-aqua & {
        color: $color_aqua;
    }
    @include respond-below(sm) {
        font-size: 16px;
    }
    @include respond-below(xs) {
        height: 51px;

        font-size: 13px;
    }
    &.selected,
    &:hover {
        color: $color_white !important;

        cursor: pointer;

        @at-root .bankitalia-palette-pink & {
            background-color: $color_pink-3;
        }
        @at-root .bankitalia-palette-blue & {
            background-color: $color_blue-2;
        }
        @at-root .bankitalia-palette-orange & {
            background-color: $color_orange;
        }
        @at-root .bankitalia-palette-green & {
            background-color: $color_green-2;
        }
        @at-root .bankitalia-palette-aqua & {
            background-color: $color_aqua;
        }
    }
    &.dropped {
        opacity: 0.35;

        &:hover {
            opacity: 1;
        }
    }
}