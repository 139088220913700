/* ==========================================================================
   Article
   ========================================================================== */

.bankitalia-article {
    &__figure {
        margin-bottom: 20px;
        height: 110px;

        @include respond-below(xs) {
            height: 90px;
        }
        img {
            max-height: 100%;
        }
    }
    &__title {
        @extend .bankitalia-title;
        @extend .bankitalia-title--alt-2;

        display: block;
        margin-bottom: 30px;

        color: $color_blue-1;

        @include respond-below(xs) {
            margin-bottom: 15px;
        }
    }
    &__txt {
        p {
            font-size: 18px;
            color: $color_gray-2;

            @include respond-below(xs) {
                font-size: 16px;
            }
        }
    }
}