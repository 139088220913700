/* ==========================================================================
   Fonts
   ========================================================================== */

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/rubik-medium-webfont.eot');
    src: url('../fonts/rubik-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/rubik-medium-webfont.woff2') format('woff2'),
         url('../fonts/rubik-medium-webfont.woff') format('woff'),
         url('../fonts/rubik-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/rubik-bold-webfont.eot');
    src: url('../fonts/rubik-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/rubik-bold-webfont.woff2') format('woff2'),
         url('../fonts/rubik-bold-webfont.woff') format('woff'),
         url('../fonts/rubik-bold-webfont.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

/*========================================================================== */

@font-face {
     font-family: 'FaunaOne';
     src: url('../fonts/faunaone-regular-webfont.eot');
     src: url('../fonts/faunaone-regular-webfont.eot?#iefix') format('embedded-opentype'),
          url('../fonts/faunaone-regular-webfont.woff2') format('woff2'),
          url('../fonts/faunaone-regular-webfont.woff') format('woff'),
          url('../fonts/faunaone-regular-webfont.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
}

/*========================================================================== */

@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/firasans-bold-webfont.eot');
    src: url('../fonts/firasans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/firasans-bold-webfont.woff2') format('woff2'),
         url('../fonts/firasans-bold-webfont.woff') format('woff'),
         url('../fonts/firasans-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
     font-family: 'FiraSans';
     src: url('../fonts/firasans-black-webfont.eot');
     src: url('../fonts/firasans-black-webfont.eot?#iefix') format('embedded-opentype'),
          url('../fonts/firasans-black-webfont.woff2') format('woff2'),
          url('../fonts/firasans-black-webfont.woff') format('woff'),
          url('../fonts/firasans-black-webfont.ttf') format('truetype'),
          url('../fonts/firasans-black-webfont.svg#FiraSans') format('svg');
     font-weight: 900;
     font-style: normal; 
 }