/* ==========================================================================
   Footer
   ========================================================================== */

.bankitalia-footer {
    &__row {
        justify-content: space-between;
    }
    &__col {
        &--logo {
            @include respond-below(xs) {
                display: flex;
                justify-content: center;

                margin-bottom: 20px;
            }
        }
        &--pagination {
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond-below(md) {
                display: none;
            }
        }
        &--copyright {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            @include respond-below(xs) {
                justify-content: center;
            }
        }
    }
    .bankitalia-logo {
        img {
            width: auto;
            height: 45px;
        }
    }
    .bankitalia-copyright {
        margin-bottom: 0;

        font-size: 14px;
        font-family: $font_faunaOne;
    }
    .bankitalia-carousel-pagination {
        @include respond-below(sm) {
            margin-top: -70px;
        }
    }
}