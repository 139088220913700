/* ==========================================================================
	States
    ========================================================================== */


.scroll-lock,
.modal-open,
.menu-opened {
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding-right: 0 !important;
}

/*========================================================================== */

* .bankitalia-header {
	&__col {
		&--nav {
			transition: all 400ms ease-in !important;
		}
	}
}

.menu-opened {
	.bankitalia-header {
		&__col {
			&--nav {
				transition: all 400ms ease-out !important;
				transform: translateX(0);

				box-shadow: $box_shadow-default;
				opacity: 1;
			}
		}
	}
}