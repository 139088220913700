/* ==========================================================================
   Typography
   ========================================================================== */

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color_white;
}

/*========================================================================== */

h1,
.bankitalia-title {
    font-family: $font-firaSans;
    font-size: 52px;
    font-weight: bold;
    line-height: 1.1;

    @include respond-below(lg) {
        font-size: 46px;
    }
    @include respond-below(md) {
        font-size: 40px;
    }
    @include respond-below(sm) {
        font-size: 36px;
    }
    @include respond-below(xs) {
        font-size: 32px;
    }

    &--alt {
        font-size: 32px;

        @include respond-below(sm) {
            font-size: 28px;
        }
        @include respond-below(xs) {
            font-size: 24px;
        }
    }
    &--alt-2 {
        font-size: 42px;

        @include respond-below(sm) {
            font-size: 36px;
        }
        @include respond-below(xs) {
            font-size: 28px;
        }
    }
    &--alt-3 {
        font-size: 48px;

        @include respond-below(sm) {
            font-size: 40px;
        }
        @include respond-below(xs) {
            font-size: 34px;
        }
    }
}

/*========================================================================== */

h2,
h2 *,
.bankitalia-title-h2 {
    font-family: $font-rubik;
    font-size: 42px;

    @include respond-below(lg) {
        font-size: 40px;
    }
    @include respond-below(md) {
        font-size: 38px;
    }
}

h3,
h3 *,
.bankitalia-title-h3 {
    font-family: $font-rubik;
    font-size: 30px;

    @include respond-below(lg) {
        font-size: 28px;
    }
    @include respond-below(md) {
        font-size: 28px;
    }
}

/*========================================================================== */

p {
    font-family: $font-faunaOne;
    font-size: 20px;
    line-height: 1.3;

    @include respond-below(lg) {
        font-size: 19px;
    }
    @include respond-below(md) {
        font-size: 18px;
    }
    @include respond-below(sm) {
        font-size: 17px;
    }
    @include respond-below(xs) {
        font-size: 15px;
    }
}
p.bankitalia-teachers-par,
p.bankitalia-teachers-par * {
    font-size: 12px !important;
}