/* ==========================================================================
	Buttons & links
	========================================================================== */

a,
button {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	transition: opacity 300ms ease-in-out;

	&:hover {
		opacity: 0.7;
	}
}

button {
	border: none;
	background-color: transparent;

	&:focus {
		outline:0;
	}
}

/*========================================================================== */

.bankitalia-link {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

/*========================================================================== */

.bankitalia-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: relative;

    height: 43px;
    min-width: 140px;
    padding-left: 25px;
    padding-right: 25px;

    font-family: $font_rubik;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;

    border: none;
    border-radius: 100px;

    transition: all ease-in-out 300ms;

    @at-root .bankitalia-palette-pink & {
        background-color: $color_pink-1;
        border: 1px solid $color_pink-1;
        box-shadow: 4px 4px 0 1px rgba($color_pink-1,0.4);
    }
    @at-root .bankitalia-palette-blue & {
        background-color: darken($color_blue-2, 7%);
        border: 1px solid darken($color_blue-2, 7%);
        box-shadow: 4px 4px 0 1px rgba($color_blue-2,0.7);
    }
    @at-root .bankitalia-palette-orange & {
        background-color: darken($color_orange, 7%);
        border: 1px solid darken($color_orange, 7%);
        box-shadow: 4px 4px 0 1px rgba($color_orange,0.9);
    }
    @at-root .bankitalia-palette-green & {
        background-color: darken($color_green-2, 7%);
        border: 1px solid darken($color_green-2, 7%);
        box-shadow: 4px 4px 0 1px rgba($color_green-2,0.8);
    }
    @at-root .bankitalia-palette-aqua & {
        background-color: darken($color_aqua, 7%);
        border: 1px solid darken($color_aqua, 7%);
        box-shadow: 4px 4px 0 1px rgba($color_aqua,0.9);
    }
    &__label {
        transition: all ease-in-out 300ms;
    }
    &:hover:not(.disabled) {
        background-color: $color_white;
        border-color: transparent;
        opacity: 1;

        .bankitalia-btn {
            &__label {
                @at-root .bankitalia-palette-pink & {
                    color: $color_pink-1;
                }
                @at-root .bankitalia-palette-blue & {
                    color: darken($color_blue-2, 7%);
                }
                @at-root .bankitalia-palette-orange & {
                    color: darken($color_orange, 7%);
                }
                @at-root .bankitalia-palette-green & {
                    color: darken($color_green-2, 7%);
                }
                @at-root .bankitalia-palette-aqua & {
                    color: darken($color_aqua, 7%);
                }
			}
        }
    }
    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
    &--bankitalia-palette-green {
        background-color: $color_green-1;
        border-color: $color_white;
        box-shadow: none;

        &:hover:not(.disabled) {
            .bankitalia-btn {
                &__label {
                    color: $color_green-1;
                }
            }
        }
    }
    &--negativeViolet {
        background-color: $color_white !important;
        border-color: transparent;
        box-shadow: 4px 4px 0 1px $color_violet-2 !important;

        .bankitalia-btn {
            &__label {
                color: $color_violet-2;
            }
        }
        &:hover:not(.disabled) {
            background-color: $color_violet-2 !important;
            box-shadow: 4px 4px 0 1px rgba($color_violet-2,0.4) !important;

            .bankitalia-btn {
                &__label {
                    color: $color_white !important;
                }
            }
        }
    }
}

/*========================================================================== */

.bankitalia-circle {
    position: relative;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 56px;
    height: 56px;

    border: 2px solid rgba($color_white, 0.5);
    border-radius: 600px;

    cursor: pointer;

    transition: all ease-in-out 300ms;

    @include respond-below(sm) {
        width: 46px;
        height: 46px;
    }
    @include respond-below(xs) {
        width: 40px;
        height: 40px;
    }

    &--disabled {
        opacity: 0.4 !important;
        cursor: default !important;
    }
    &--disabled:hover {
        &:after {
            background-color: #fff !important;
        }
    }

    &--active,
    &--active:hover {
        z-index: 2147483648 !important;
        opacity: 0.4 !important;
        cursor: default !important;

        &:after {
            @at-root .bankitalia-palette-pink & {
                background-color: $color_pink-4 !important;
            }
            @at-root .bankitalia-palette-blue & {
                background-color: darken($color_blue-2, 7%) !important;
            }
            @at-root .bankitalia-palette-orange & {
                background-color: darken($color_orange, 7%) !important;
            }
            @at-root .bankitalia-palette-green & {
                background-color: darken($color_green-2, 7%) !important;
            }
            @at-root .bankitalia-palette-aqua & {
                background-color: darken($color_aqua, 7%) !important;
            }
        }
    }

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 600px;

        content: "";
        transition: all ease-in-out 300ms;
    }
    &:before {
        width: 44px;
        height: 44px;

        background-color: rgba($color_white, 0.5);

        @include respond-below(sm) {
            width: 36px;
            height: 36px;
        }
        @include respond-below(xs) {
            width: 30px;
            height: 30px;
        }
    }
    &:after {
        width: 24px;
        height: 24px;

        background-color: $color_white;

        @include respond-below(sm) {
            width: 18px;
            height: 18px;
        }
        @include respond-below(xs) {
            width: 16px;
            height: 16px;
        }
    }
    &:hover,
    &.active {
        opacity: 1;

        &:after {
            @at-root .bankitalia-palette-pink & {
                background-color: $color_pink-4;
            }
            @at-root .bankitalia-palette-blue & {
                background-color: darken($color_blue-2, 7%);
            }
            @at-root .bankitalia-palette-orange & {
                background-color: darken($color_orange, 7%);
            }
            @at-root .bankitalia-palette-green & {
                background-color: darken($color_green-2, 7%);
            }
            @at-root .bankitalia-palette-aqua & {
                background-color: darken($color_aqua, 7%);
            }
        }
    }
    &__close,
    &__icon,
    &__desc {
        position: relative;
        z-index: -1;
        opacity: 0;
        transition: none;
    }
    &__close {
        margin-bottom: 30px;

        @include respond-below(md) {
            margin-bottom: 20px;
        }
        @include respond-below(xs) {
            width: 25px;
            margin-bottom: 10px;
        }
        @include respond-below(xxs) {
            margin-bottom: 15px;
        }
        &:hover {
            opacity: 0;
        }
    }
    &__icon {
        margin-bottom: 14px;
        max-height: 190px;
        margin-top: -30px;

        @include respond-below(md) {
            max-height: 90px;
        }
        @include respond-below(sm) {
            max-height: 70px;
        }
        @include respond-below(xs) {
            max-height: 45px;
        }
        @include respond-below(xxs) {
            display: none;
        }
    }
    &__desc {
        width: 300px;
        font-size: 18px;
        line-height: calc(27/18);
        text-align: center;
        color: $color_gray-2;

        @include respond-below(md) {
            width: 280px;
            font-size: 17px;
        }
        @include respond-below(sm) {
            width: 260px;
            font-size: 15px;
        }
        @include respond-below(xs) {
            width: 200px;
            font-size: 14px;
            line-height: 1.2;
        }
        @include respond-below(xxs) {
            width: 70vw;
            font-size: 13px;
        }
    }
    &.active {
        width: 400px;
        height: 400px;
        padding-top: 60px;

        border-color: transparent;

        cursor: default;

        transition: all ease-in-out 300ms;

        @include respond-below(md) {
            width: 475px;
            height: 475px;
        }
        @include respond-below(sm) {
            width: 380px;
            height: 380px;
            padding-top: 30px;
        }
        @include respond-below(xs) {
            width: 310px;
            height: 310px;
        }
        @include respond-below(xxs) {
            width: 95vw;
            height: 95vw;
        }

        z-index: 2147483648 !important;

        &.big {
            width: calc(400px * 1.5);
            height: calc(400px * 1.5);

            @include respond-below(md) {
                width: calc(475px * 1.25);
                height: calc(475px * 1.25);
            }
            @include respond-below(sm) {
                width: calc(380px * 1.25);
                height: calc(380px * 1.25);
                padding-top: calc(30px * 1.25);
            }
            @include respond-below(xs) {
                width: calc(310px * 1.25);
                height: calc(310px * 1.25);
            }
            @include respond-below(xxs) {
                width: 100vw;
                height: 100vw;
            }

            .bankitalia-circle__desc {
                width: calc(300px * 1.25);

                @include respond-below(md) {
                    width: calc(280px * 1.25);
                    font-size: 17px;
                }
                @include respond-below(sm) {
                    width: calc(260px * 1.25);
                    font-size: 15px;
                }
                @include respond-below(xs) {
                    width: calc(200px * 1.25);
                    font-size: 14px;
                    line-height: 1.2;
                }
                @include respond-below(xxs) {
                    width: 75vw;
                }
                p {
                    @include respond-below(xxs) {
                        font-size: 14px;
                    }
                }
            }
        }
        &:before {
            width: 100%;
            padding-top: 100%;
        }
        &:after {
            width: 90%;
            height: 90%;
            background-color: $color_white !important;

            @include respond-below(md) {
                width: 91%;
                height: 91%;
            }
            @include respond-below(sm) {
                width: 93%;
                height: 93%;
            }
        }
        .bankitalia-circle {
            &__close,
            &__icon,
            &__desc {
                z-index: 1;
                opacity: 1;
                transition: opacity ease-in-out 500ms 300ms;
            }
        }
    }

    &--warning {
        &:after {
            background-color: $color_pink-4;
        }
    }
}

/*========================================================================== */

.bankitalia-ico-btn {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    border-radius: 100px;
    background-color: $color_white;

    box-shadow: 4px 4px 0 1px rgba($color_pink-1,0.4);

    &__icon {
        max-width: 35px;
    }
    &__label {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);

        font-family: $font_rubik;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $color_white;
    }
    &--negative {
        background-color: $color_pink-1;

        @at-root .bankitalia-palette-blue & {
            background-color: darken($color_blue-2, 7%);
            box-shadow: 4px 4px 0 1px rgba($color_blue-2,0.7);
        }
        @at-root .bankitalia-palette-orange & {
            background-color: darken($color_orange, 7%);
            box-shadow: 4px 4px 0 1px rgba($color_orange,0.9);
        }
        @at-root .bankitalia-palette-green & {
            background-color: darken($color_green-2, 7%);
            box-shadow: 4px 4px 0 1px rgba($color_green-2,0.8);
        }
        @at-root .bankitalia-palette-aqua & {
            background-color: darken($color_aqua, 7%);
            box-shadow: 4px 4px 0 1px rgba($color_aqua,0.9);
        }
    }
    &--with-label {
        margin-bottom: 40px;
    }
}

/*========================================================================== */

.bankitalia-card {

}


.Bankitalia_ToggleSwitch {
	position: relative;
	width: 5rem;
	height: 2.35rem;
	display: inline-block;

	&.Bankitalia_ToggleSwitch__rounded {
		.Slider {
			border-radius: 15rem;
			background-color: rgba(255, 255, 255, 0.5);
			border: 1px solid #A0A0A0;

			&:before {
				border-radius: 50%;
			}
		}
	}

	.Bankitalia_ToggleSwitch__wrapper {
		position: relative;
		width: 5rem;
		height: 2.35rem;

		.Slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: .4s ease;

			&:before {
				width: 1.8rem;
				height: 1.8rem;
				position: absolute;

                background-color: $color_pink-1;

                @at-root .bankitalia-palette-blue & {
                    background-color: darken($color_blue-2, 7%);
                }
                @at-root .bankitalia-palette-orange & {
                    background-color: darken($color_orange, 7%);
                }
                @at-root .bankitalia-palette-green & {
                    background-color: darken($color_green-2, 7%);
                }
                @at-root .bankitalia-palette-aqua & {
                    background-color: darken($color_aqua, 7%);
                }

				content: '';
				margin: 0;
				padding: 0;
				top: 50%;
				left: 0.20rem;
				transform: translateY(-50%);
				transition: .4s;
				cursor: pointer;
				box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
			}

			&.isChecked {
                background-color: lighten($color_pink-1, 18%);

                @at-root .bankitalia-palette-blue & {
                    background-color: darken($color_blue-2, 7%);
                }
                @at-root .bankitalia-palette-orange & {
                    background-color: lighten($color_orange, 20%);
                }
                @at-root .bankitalia-palette-green & {
                    background-color: lighten($color_green-2, 20%);
                }
                @at-root .bankitalia-palette-aqua & {
                    background-color: darken($color_aqua, 7%);
                }

				&:before {
                    left: calc(100% - 0.20rem - 1.8rem);

                    background-color: $color_pink-1;

                    @at-root .bankitalia-palette-blue & {
                        background-color: darken($color_blue-2, 7%);
                    }
                    @at-root .bankitalia-palette-orange & {
                        background-color: darken($color_orange, 7%);
                    }
                    @at-root .bankitalia-palette-green & {
                        background-color: darken($color_green-2, 7%);
                    }
                    @at-root .bankitalia-palette-aqua & {
                        background-color: darken($color_aqua, 7%);
                    }
				}
			}
		}
	}
}