/* ==========================================================================
   Chapters
   ========================================================================== */

.bankitalia-chapters {
    @extend %helper--empty-list;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include respond-below(md) {
        flex-wrap: wrap;
    }

    &__item {

        width: calc(20% - 18px);
        max-width: 250px;
        margin: 0 9px;

        @include respond-below(lg) {

            width: calc(20% - 10px);
            margin: 0 5px;
        }
        @include respond-below(md) {
            width: calc((100% / 3) - 18px);
            margin: 0 9px 18px;
        }
        @include respond-below(sm) {
            width: calc(50% - 10px);
        }
        @include respond-below(xs) {

            width: 100%;
            margin-bottom: 25px;
        }
    }
}

/*========================================================================== */

.bankitalia-chapter {
    position: relative;

    display: flex;
    background-color: $color_white;

    border-radius: 200px;

    &:before {
        display: block;

        width: 100%;
        padding-top: 100%;

        content: "";
    }
    &__inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        margin: auto;
    }
    &__figure {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60%;
        padding-top: 15px;
        padding-bottom: 15px;

        @include respond-below(lg) {
            height: 55%;
            padding-bottom: 0;
        }
        img {
            max-height: 100px;

            @include respond-below(lg) {
                max-width: 80px;
                max-height: 80px;
            }
        }
    }
    &__name {
        width: 100%;

        height: 40%;
        padding: 0 20px;
        margin-bottom: 0;

        font-family: $font-firaSans;
        font-size: 20px;
        line-height: 24px;
        color: $color_gray-2;
        text-align: center;

        @include respond-below(lg) {
            height: 45%;

            font-size: 18px;
        }
    }
    &--alt {
        padding: 17px 60px 17px 50px !important;
        background-color: transparent;

        border-radius: 0;

        @include respond-below(lg) {
            padding-left: 25px !important;
        }
        @include respond-below(sm) {
            padding: 12px 60px 12px 20px !important;
        }
        &:before {
            display: none;
        }
        .bankitalia-chapter {
            &__inner {
                position: static;

                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                height: auto;
                margin: 0;
            }
            &__figure {
                flex-shrink: 0;
                align-items: center;
                justify-content: center;
                width: 115px;
                height: 115px;
                padding-top: 0;
                padding-bottom: 0;
                margin-right: 30px;

                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 100px;

                @include respond-below(sm) {
                    width: 80px;
                    height: 80px;
                    margin-right: 20px;
                }
                @include respond-below(xs) {
                    width: 70px;
                    height: 70px;
                    margin-right: 15px;
                }
                img {
                    max-width: 65px;

                    @include respond-below(sm) {
                        max-width: 45px;
                    }
                    @include respond-below(xs) {
                        max-width: 40px;
                    }
                }
            }
            &__name {
                @at-root .bankitalia-palette-pink & {
                    color: $color_pink-3;
                }
                @at-root .bankitalia-palette-blue & {
                    color: darken($color_blue-2, 25%);
                }
                @at-root .bankitalia-palette-orange & {
                    color: darken($color_orange, 25%);
                }
                @at-root .bankitalia-palette-green & {
                    color: darken($color_green-2, 25%);
                }
                @at-root .bankitalia-palette-aqua & {
                    color: darken($color_aqua, 25%);
                }
                height: auto;
                padding: 0;
                margin-top: 0;

                font-size: 32px;
                line-height: 1.2;
                text-align: left;
                letter-spacing: 0;
                @include respond-below(md) {
                    font-size: 28px;
                }
                @include respond-below(sm) {
                    font-size: 24px;
                }
                @include respond-below(xs) {
                    font-size: 20px;
                }
            }
        }
    }

    &--alt-active {
        @at-root .bankitalia-palette-pink & {
            background-color: lighten($color_pink-3, 12);
        }
        @at-root .bankitalia-palette-blue & {
            background-color: $color_blue-2;
        }
        @at-root .bankitalia-palette-orange & {
            background-color: $color_orange;
        }
        @at-root .bankitalia-palette-green & {
            background-color: $color_green-2;
        }
        @at-root .bankitalia-palette-aqua & {
            background-color: $color_aqua;
        }

        .bankitalia-chapter {
            &__figure {
                @at-root .bankitalia-palette-pink & {
                    background-color: lighten($color_pink-3, 5);
                }
                @at-root .bankitalia-palette-blue & {
                    background-color: darken($color_blue-2, 25%);
                }
                @at-root .bankitalia-palette-orange & {
                    background-color: darken($color_orange, 25%);
                }
                @at-root .bankitalia-palette-green & {
                    background-color: darken($color_green-2, 25%);
                }
                @at-root .bankitalia-palette-aqua & {
                    background-color: darken($color_aqua, 25%);
                }
            }
        }
    }
}

/*========================================================================== */

.bankitalia-chapters2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include respond-below(sm) {
        flex-direction: column;
        justify-content: flex-start;
    }
    &__item {
        width: calc((100% / 3) - 40px);

        @include respond-below(md) {
            width: calc((100% / 3) - 15px);
        }
        @include respond-below(sm) {
            width: 100%;
        }
        & + & {
            @include respond-below(sm) {
                margin-top: 27px;
            }   
        }
    }
}

/*========================================================================== */

.bankitalia-chapter2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @include respond-below(sm) {
        flex-direction: row;
    }
    &__figure {
        margin-bottom: 30px;
        border-radius: 50%;
        box-shadow: 0 10px 0 1px rgba(#4c688d,1);

        @include respond-below(sm) {
            width: 96px;
            height: 96px;

            margin-right: 24px;
            margin-bottom: 0;

            box-shadow: none;
        }
        @include respond-below(xs) {
            width: 64px;
            height: 64px;

            margin-right: 12px;
        }
    }
    &__name {
        font-family: $font-rubik;
        font-weight: 500;
        font-size: 30px;
        text-align: center;

        @include respond-below(md) {
            font-size: 26px;
        }
        @include respond-below(sm) {
            font-size: 18px;
            text-align: left;
        }
        br {
            @include respond-below(sm) {
                display: none;
            }
        }
    }
}