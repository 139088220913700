/* ==========================================================================
   Global
   ========================================================================== */

html,
body {
	width: 100%;
	min-width: 320px;
	line-height: 1.2;
	font-family: $font_default;
	font-size: 12px;
	scroll-behavior: smooth;
}

body {
	&.bankitalia-palette-pink {
		background-color: $color_pink-2;
	}
	&.bankitalia-palette-blue {
		background-color: $color_blue-2;
	}
	&.bankitalia-palette-orange {
		background-color: $color_orange;
	}
	&.bankitalia-palette-green {
		background-color: $color_green-2;
	}
	&.bankitalia-palette-aqua {
		background-color: $color_aqua;
	}
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	word-wrap: break-word;
	outline: none;
}

img {
	display: block;
	max-width: 100%;
	border: 0;
}

.container {
	max-width: 1366px;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: $color_gray-4;
}
::-webkit-scrollbar-thumb {
	background: $color_gray-1;
}
::-webkit-scrollbar-thumb:hover {
	background: $color_gray-1;
}

.js-lazy {
	transition: 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) all;
	transform: translateY(50px);
	opacity: 0;
}
.animated {
	opacity: 1;
	transform: none;
}