/* ==========================================================================
   Paginations
   ========================================================================== */

.bankitalia-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 30px;

    &__item {
        position: relative;
        margin: 0 15px;

        width: 30px;
        height: 30px;

        background-color: transparent;
        border-radius: 100px;
        border: none;

        cursor: pointer;

        @include respond-below(md) {
            margin: 0 7px;
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 14px;
            height: 14px;

            background-color: $color-white;
            border-radius: 100px;

            content: "";
            transition: all ease-in-out 300ms;
        }
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 14px;
            height: 14px;

            border-radius: 100px;
            border: 2px solid $color_white;

            content: "";
            transition: all ease-in-out 300ms;
        }
        &:hover {
            &:after {
                width: 4px;
                height: 4px;
            }
            &:before {
                width: 22px;
                height: 22px;
            }
        }
        &.active {
            cursor: default;

            &:after {
                width: 14px;
                height: 14px;
            }
            &:before {
                width: 30px;
                height: 30px;
            }
            &:hover {
                transform: none;
            }
        }
    }
    &--chapters {
        height: 24px;

        .bankitalia-pagination {
            &__item {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 24px;
                width: auto;
                min-width: 24px;

                font-family: $font_rubik;
                font-size: 16px;

                background-color: $color_white;

                opacity: 0.4;

                @at-root .bankitalia-palette-pink & {
                    color: $color_pink-3;
                }
                @at-root .bankitalia-palette-blue & {
                    color: $color_blue-2;
                }
                @at-root .bankitalia-palette-orange & {
                    color: $color_orange;
                }
                @at-root .bankitalia-palette-green & {
                    color: $color_green-2;
                }
                @at-root .bankitalia-palette-aqua & {
                    color: $color_aqua;
                }
                &:after,
                &:before {
                    display: none;
                }
                &__label {
                    display: none;

                    font-weight: 500;
                }
                &__nr {
                    font-family: $font_firaSans;
                }
                &.active {
                    padding: 0 13px;

                    opacity: 1;

                    .bankitalia-pagination {
                        &__item {
                            &__label {
                                display: block;
                            }
                            &__nr {
                                margin-left: 4px;

                                font-family: $font_rubik;
                                font-weight: bold;
                            }
                        }
                    }
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}