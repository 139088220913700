/* ==========================================================================
   Forms
   ========================================================================== */


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: $color-black;
}
::-moz-placeholder { /* Firefox 19+ */
	 color: $color-black;
}
:-ms-input-placeholder { /* IE 10+ */
	 color: $color-black;
}
:-moz-placeholder { /* Firefox 18- */
	 color: $color-black;
}

/*========================================================================== */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*========================================================================== */

label {
	cursor: pointer;
}
textarea {
	resize: none;
}
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {	
	width: 100%;
}
fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

/*========================================================================== */

.c-input-text {	
}

.warn {
	.c-input-text {		
	}
}

/*========================================================================== */

.c-textarea {
}

.warn {
	.c-textarea {		
	}
}

/*========================================================================== */

.c-checkbox {
	&__input {
		display: none;

		&:checked + .c-checkbox__label {	

			&:before {
			}
		}
	}
	&__label {

		&:before {
		}
	}
}

.warn {
	.c-checkbox {
		&__label {

			&:before {
			}
		}
	}
}