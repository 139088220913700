/* ==========================================================================
   Helpers
   ========================================================================== */

%helper {
	&--inner {
		display: flex;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-left: 30px;
		padding-right: 30px;
	}
	&--bg-covered {
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
	}
	&--bg-contained {
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
	}
	&--vertical-align {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
	&--pseudo-triangle {
		width: 0;
		height: 0;
		border-style: solid;
		content: '';
	}	
	&--empty-list {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
}

// how to use? e.g. @extend %helper--clear