/* ==========================================================================
   Content
   ========================================================================== */

.bankitalia-app {
    @extend %helper--bg-covered;
        @at-root .bankitalia-palette-blue & {
            background-color: $color_blue-2;
            background-image: url(/images/backgrounds/bg_all.jpg);
            background-repeat: no-repeat;
            background-position: 0% 0%;
            background-size: 500% 100%;
        }
        @at-root .bankitalia-palette-pink & {
            background-color: $color_pink-2;
            background-image: url(/images/backgrounds/bg_all.jpg);
            background-repeat: no-repeat;
            background-position: 25% 0%;
            background-size: 500% 100%;
        }
        @at-root .bankitalia-palette-orange & {
            background-color: $color_orange;
            background-image: url(/images/backgrounds/bg_all.jpg);
            background-repeat: no-repeat;
            background-position: 50% 0%;
            background-size: 500% 100%;
        }
        @at-root .bankitalia-palette-green & {
            background-color: $color_green-2;
            background-image: url(/images/backgrounds/bg_all.jpg);
            background-repeat: no-repeat;
            background-position: 75% 0%;
            background-size: 500% 100%;
        }
        @at-root .bankitalia-palette-aqua & {
            background-color: $color_aqua;
            background-image: url(/images/backgrounds/bg_all.jpg);
            background-repeat: no-repeat;
            background-position: 100% 0%;
            background-size: 500% 100%;
        }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 24px;

    overflow: hidden;

       // transition: background-color 350ms ease-out;

        // @at-root .bankitalia-palette-pink & {
        //     background-color: $color_pink-2;
        // }
        // @at-root .bankitalia-palette-blue & {
        //     background-color: $color_blue-2;
        // }
        // @at-root .bankitalia-palette-orange & {
        //     background-color: $color_orange;
        // }
        // @at-root .bankitalia-palette-green & {
        //     background-color: $color_green-2;
        // }
        // @at-root .bankitalia-palette-aqua & {
        //     background-color: $color_aqua;
        // }

        transition: background-position 250ms ease-in;

    &--mainpage {
        .bankitalia-hero {
            margin-top: 40px;
            margin-bottom: 40px;

            @include respond-below(md) {
                margin-bottom: 20px;
            }
        }
        .swiper-container {
            overflow: visible;
        }
    }
    &--school {
        .bankitalia-main {
            margin-top: -20px;
            margin-bottom: 20px;

            @include respond-below(md) {
                margin-top: 20px;
            }
            @include respond-below(xs) {
                margin-top: 40px;
                margin-bottom: 20px;
            }

            &__row {
                &--info {
                    margin-bottom: 65px;

                    @include respond-below(lg) {
                        margin-bottom: 35px;
                    }
                    .col {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        text-align: center;
                    }
                    p {
                        max-width: 600px;
                    }
                }
            }
            .bankitalia-title {
                margin-bottom: 15px;
            }
            .bankitalia-title-2 {
                margin-bottom: 25px;

                @include respond-below(lg) {
                    margin-bottom: 20px;
                }
                @include respond-below(md) {
                    margin-bottom: 15px;
                }
            }
            & > p {
                font-size: 22px;
            }
        }
        .bankitalia-chapters {

            width: calc(100% + 18px);
            margin-right: -9px;
            margin-left: -9px;

            @include respond-below(lg) {

                width: calc(100% + 10px);
                margin-right: -5px;
                margin-left: -5px;
            }
        }
    }
    &--start,
    &--game {
        background-image: url(/images/backgrounds/bg_pink.jpg);

        .bankitalia-header {
            &__col {
                &--nav {
                    position: fixed;
                    top: 0;
                    right: 0;
                    z-index: 10000;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 80%;
                    min-width: 320px;
                    max-width: 700px;
                    height: 100vh;

                    background-color: $color_white;

                    opacity: 0;
                    transform: translateX(calc(100vw + 50px));
                }
                &--hamburger {
                    display: flex;
                }
            }
        }
        .bankitalia-navigation-close {
            display: block;
        }
        .bankitalia-main {
            margin-top: 40px;
            margin-bottom: 40px;

            @include respond-below(md) {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
    &--start {
        .bankitalia-main {
            padding-top: 25px;
            padding-bottom: 25px;

            @include respond-below(sm) {
                margin-top: 20px;
                margin-bottom: 0;
            }
        }
    }
    &--game {
        .bankitalia-info-panel {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 2147483648 !important;
            width: 50vw;
            min-width: 320px;
            max-width: 550px;

            height: 100vh;
            background-color: #fff;
            /* opacity: 0; */
            transition: all ease-in-out 300ms;
            transform: translateX(100vw);

            @include respond-below(md) {
                width: 60vw;
            }
            @include respond-below(sm) {
                width: 75vw;
            }
            @include respond-below(xs) {
                transform: translateX(calc(100vw + 46px));

                width: 100vw;
                max-width: none;
            }
            &--active {
                transform: translateX(0);
            }
            &__body {
                align-items: flex-start;

                height: calc(100vh - 20px);
                padding: 100px 21px 10px 35px;

                @include respond-below(sm) {
                    padding-left: 20px;
                }
                @include respond-below(xs) {
                    padding: 0 0 0 15px;
                    height: 100vh;
                    display: flex;
                    align-items: center;
                }

                .bankitalia-article {
                    padding: 20px 35px 0 0;
                    overflow: auto;
                    max-height: calc(100vh - 150px);

                    @include respond-below(xs) {
                        padding-top: 10px;
                        padding-right: 45px;
                    }
                    @include respond-below(xxs) {
                        padding-right: 10px;
                    }
                    &__figure {

                        width: 100%;
                        height: auto;
                        margin: 0 auto 10px;
                    }
                    &__title {
                        @include respond-below(md) {
                            margin-bottom: 25px;
                            font-size: 38px;
                        }
                        @include respond-below(sm) {
                            margin-bottom: 20px;
                            font-size: 32px;
                        }
                        @include respond-below(xxs) {
                            font-size: 28px;
                        }
                    }
                    &__txt {
                        p {
                            @include respond-below(sm) {
                                font-size: 16px;
                            }
                        }
                    }
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: -46px;
                    width: 46px;

                    height: 100vh;

                    content: "";
                    background-image: url(/images/backgrounds/nav-edge.png);

                    background-repeat: no-repeat;
                    background-size: 46px 100%;
                }
            }
        }
    }
    &--modals {
        align-items: center;
        justify-content: center;
        background-image: url(/images/backgrounds/bg_pink.jpg);

        .bankitalia-btn {
            & + .bankitalia-btn {
                margin-top: 10px;
            }
        }
    }
    &--teachers {
        .bankitalia-main {
            &__row {
                &--heading {
                    padding-top: 60px;
                    margin-bottom: 40px;
                    text-align: center;

                    @include respond-below(sm) {
                        padding-top: 45px;
                        margin-bottom: 24px;
                    }
                }
                &--desc {
                    margin-bottom: 50px;

                    @include respond-below(sm) {
                        margin-bottom: 35px;
                    }
                    p {
                        line-height: 1.6;

                        strong {
                            font-weight: bold;
                        }
                    }
                }
                &--desc2 {
                    margin-bottom: 60px;

                    @include respond-below(sm) {
                        margin-bottom: 45px;
                    }
                    @include respond-below(xs) {
                        margin-bottom: 36px;
                    }
                    .bankitalia-title {
                        padding-top: 30px;
                    }
                    p {
                        font-size: 24px;
                        line-height: 1.6;

                        @include respond-below(sm) {
                            font-size: 20px;
                        }
                        @include respond-below(xs) {
                            font-size: 18px;
                        }
                    }
                }
                &--chapters {
                    margin-bottom: 90px;

                    @include respond-below(sm) {
                        margin-bottom: 58px;
                    }
                }
                &--area {
                    margin-right: 0;
                    margin-left: 0;
                    padding-top: 40px;
                    padding-bottom: 80px;

                    border-radius: 20px;
                    background-color: $color_blue-3;

                    @include respond-below(sm) {
                        padding-top: 27px;
                        padding-bottom: 56px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    @include respond-below(xs) {
                        padding-bottom: 40px;
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .bankitalia-title {
                        margin-bottom: 45px;

                        @include respond-below(sm) {
                            margin-bottom: 36px;
                        }
                    }
                    .bankitalia-list {
                        margin-left: 30px;
                        margin-bottom: 35px;

                        @include respond-below(sm) {
                            margin-bottom: 26px;
                            margin-left: 10px;
                        }
                    }
                    .bankitalia-featured-list {
                        margin-bottom: 35px;
                    }
                    p {
                        font-size: 24px;
                        line-height: 1.6;

                        @include respond-below(sm) {
                            font-size: 20px;
                        }
                        @include respond-below(xs) {
                            font-size: 18px;
                        }
                        & + p {
                            margin-top: 35px;

                            @include respond-below(sm) {
                                margin-top: 28px;
                            }
                            @include respond-below(xs) {
                                margin-top: 24px;
                            }
                        }
                    }
                    & + .bankitalia-separator {
                        margin-top: 65px;

                        @include respond-below(sm) {
                            margin-top: 48px;
                        }
                    }
                    & + .bankitalia-main__row--desc2 {
                        margin-top: 130px;

                        @include respond-below(sm) {
                            margin-top: 9px;
                        }
                    }
                }
                &--books {
                    margin-top: 95px;
                    margin-bottom: 160px;

                    @include respond-below(sm) {
                        margin-top: 70px;
                        margin-bottom: 100px;
                    }
                    .col-12 + .col-12 {
                        @include respond-below(xs) {
                            margin-top: 48px;
                        }
                    }
                }
            }
            .bankitalia-separator {
                & + .bankitalia-main__row--area {
                    margin-top: 65px;

                    @include respond-below(sm) {
                        margin-top: 48px;
                    }
                }
            }
        }
    }
    &--about {
        .bankitalia-kids {
            width: 1002px;
            height: 625px;
            padding-top: 0;

            @include respond-below(lg) {
                width: 900px;
                height: 561px;
            }
            @include respond-below(md) {
                width: 740px;
                height: 462px;
            }
            @include respond-below(sm) {
                width: 540px;
                height: 337px;
            }
            @include respond-below(xs) {
                width: 340px;
                height: 212px;
            }
        }
        .bankitalia-separator {
            margin-top: 90px;
            margin-bottom: 80px;

            @include respond-below(sm) {
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }
        .bankitalia-main {
            &__row {
                &--heading {
                    padding-top: 60px;

                    @include respond-below(sm) {
                        padding-top: 45px;
                        margin-bottom: 30px;
                    }
                    .col {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    h1 {
                        @include respond-below(sm) {
                            margin-bottom: 30px;
                        }
                    }
                    p {
                        width: 100%;
                    }
                }
                &--info {
                    align-items: center;

                    p {
                        line-height: 2;
                    }
                }
                &--disclaimer {
                    padding-bottom: 140px;

                    @include respond-below(sm) {
                        padding-bottom: 90px;
                    }
                    p {
                        line-height: 2;
                    }
                }
            }
            &__col {
                &--text {
                    padding-right: 40px;

                    @include respond-below(sm) {
                        order: 1;
                        padding-right: 15px;
                    }
                }
                &--image {
                    display: flex;
                    justify-content: center;

                    @include respond-below(sm) {
                        order: 0;
                        margin-bottom: 50px;
                    }
                    & + .bankitalia-main__col--text {
                        padding-right: 15px;
                        padding-left: 40px;

                        @include respond-below(sm) {
                            padding-left: 15px;
                        }
                    }
                    img {
                        @include respond-below(sm) {
                            max-width: 80%;
                        }
                    }
                }
            }
        }
        p {
            line-height: 1.6;
        }
    }
}

.bankitalia-main {

}
.bankitalia-header,
.bankitalia-main,
.bankitalia-footer {

    height: 100%;
    padding-right: 40px;
    padding-left: 40px;

    @include respond-below(md) {
        padding-right: 25px;
        padding-left: 25px;
    }
    @include respond-below(xs) {
        padding-right: 15px;
        padding-left: 15px;
    }
}