/* ==========================================================================
	Variables
	========================================================================== */


/* Colors
========================================================================== */
$color_black: 	#000;
$color_white: 	#fff;

$color_gray-1: 	#b2889e;
$color_gray-2: 	#5a6175;
$color_gray-3: 	#cecfd0;
$color_gray-4: 	#ddd;

$color_pink-1: 	#dc3c72;
$color_pink-2: 	#ed92bb;
$color_pink-3: 	#f79cc5;
$color_pink-4: 	#f13d76;

$color_violet-1:#804cdb;
$color_violet-2:#494382;

$color_blue-1: 	#9bc2e9;
$color_blue-2:	#5b92cd;
$color_blue-3:	#5b85bc;

$color_green-1:	#7fc94e;
$color_green-2: #76c793;

$color_orange:	#fbbb53;

$color_aqua:	#62c9d4;


/* Fonts
========================================================================== */
$font-default: Arial, Tahoma, sans-serif;
$font_rubik: "Rubik", $font-default;
$font_faunaOne: "FaunaOne", $font-default;
$font_firaSans: "FiraSans", $font-default;


/* Breakpoints
========================================================================== */
$breakpoints: (
	xxxs: 340px,
	xxs: 375px,
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1366px,
	xxl: 1500px
);


/* Other
========================================================================== */
$box_shadow-default: 0 0 10px 0 rgba($color_black,0.2);